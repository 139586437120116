import React from "react";
import axios from 'axios';
import { Card, CardBody, Container, Row, Button, Col } from "reactstrap";
import 'jspdf-autotable';
import { urlAPI, configTokenAXI, toastr, getUser, getDateNow, hasContentVar } from "../../variables/utils";
// COMPONENTES
import Header from "components/Headers/Header.js";
import Grilla from "components/Grillas/Grilla.js";
import FieldsGroup from "components/FieldsGroup/FieldsGroup.js";
import Loading from "components/Loading/loading";

class AudMovilidad extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      form: {
        inpFecha1: getDateNow(),
        inpFecha2: getDateNow(),
      },
      dataGrid: [],
      columnsGrid: [
        {caption: "N° Registro", dataField: "id", dataType: "string", width: "110", allowHeaderFiltering: false},
        {caption: "Cliente", dataField: "empresa", dataType: "string", width: "90"},
        {caption: "Vehiculo", dataField: "placaVehi", dataType: "string", width: "90"},
        {caption: "Fecha Hora", dataField: "fechahora", dataType: "datetime", format: 'dd/MM/yyyy HH:mm:ss', width: "150", allowHeaderFiltering: false},
        {caption: "Subidas", dataField: "sub", dataType: "number", width: "100", alignment: "center", allowHeaderFiltering: false},
        {caption: "Bajadas", dataField: "baj", dataType: "number", width: "100", alignment: "center", allowHeaderFiltering: false},
        {caption: "Puerta 1", dataField: "isPuerta1", dataType: "boolean", width: "100", alignment: "center"},
        {caption: "Latitud", dataField: "lat", dataType: "string", width: "110", allowHeaderFiltering: false},
        {caption: "Longitud", dataField: "lng", dataType: "string", width: "110", allowHeaderFiltering: false},
        {caption: "Rumbo", dataField: "course", dataType: "string", width: "100", alignment: "center"},
        {caption: "Velocidad (km/h)", dataField: "speed", dataType: "string", width: "150", alignment: "center"},
        {caption: "Fecha Sistema", dataField: "fecha_accion", dataType: "datetime", format: 'dd/MM/yyyy HH:mm:ss', minWidth: "150", allowHeaderFiltering: false},
      ],
      columnsGridTotales:[
        {column: "sub", summaryType: "sum", displayFormat: "{0}"},
        {column: "baj", summaryType: "sum", displayFormat: "{0}"},
      ],
      showLoading: false
    };
  }

  //#region  *********** REQUEST ***********

  getDataGrid = () => {
    let user = getUser();
    if(this.state.form.inpFecha1 == "" || this.state.form.inpFecha2 == ""){
      toastr.warn("¡Porfavor verificar las fechas ingresadas!");
      return false;
    }

    this.setState({ showLoading: true });
    
    axios.post(urlAPI + "/aud_movilidad/getAllByFechas", { idCliente: user.idCliente, f1: this.state.form.inpFecha1, f2: this.state.form.inpFecha2 }, configTokenAXI)
    .then(response => {
        this.setState({ showLoading: false, dataGrid: response.data.data });

    }).catch(error => {
        this.setState({ showLoading: false });

        let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
        toastr.error(res);
    });
  }

  //#endregion

  handleChangeInputs = (e, nombreInput = null) => {
    let name = "";
    let value = "";
    
    if(typeof e.target === "undefined"){ // SELECTS
      name = nombreInput;
      value = e.id;
    }else{
      if(hasContentVar(e.target.attributes.maxLength)){
        if(e.target.value.length > e.target.attributes.maxLength.value) return false;
      }
      name = e.target.name;
      value = e.target.value;
    }

    this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
    })
  }

  componentDidMount = (e) => {
    this.getDataGrid();
  }

  render() {
    return (
      <>
        <Loading show={this.state.showLoading} />

        <Header />
        <Container className="mt--10" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody className="py-3">
                  <div className="py-2 mb-2">
                      <h5 className="heading-small text-muted m-0 text-center f-20 p-0"> HISTORIAL ENVIO DE PASAJEROS </h5>
                  </div>
                  <div className="container-fluid bg-style-2 py-2 px-3 rounded">
                    <Row className="py-1 pb-2">
                      <Col sm="3" md="2" className="px-2">
                        <FieldsGroup type="date" caption="Fecha Inicial:" id="inpFecha1" value={this.state.form} attr={{onChange: e => this.handleChangeInputs(e) }} />
                      </Col>
                      <Col sm="3" md="2" className="px-2">
                        <FieldsGroup type="date" caption="Fecha Final:" id="inpFecha2" value={this.state.form} attr={{onChange: e => this.handleChangeInputs(e) }}/>
                      </Col>
                      <Col sm="3" md="2">
                        <label className="form-control-label"></label>
                        <Button className="btn-icon btn-3 center-div mt-2 py-2 px-2 w-100 f-13" color="primary" type="button" onClick={() => this.getDataGrid()}>
                          <i className="fas fa-search mr-2"></i> Buscar
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <Grilla 
                    data={this.state.dataGrid} 
                    columns={this.state.columnsGrid}
                    columnsTotales={this.state.columnsGridTotales}
                    height={310}
                    keyExpr="id"
                  />
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default AudMovilidad;
