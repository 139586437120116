import React from "react";
import axios from 'axios';
import { Card, CardBody, Container, Row, Button, Col } from "reactstrap";
import 'jspdf-autotable';
import { urlAPI, urlAPI_SIBUS, configTokenAXI, configTokenSIBUS, toastr, getUser, hasContentVar } from "../../variables/utils";
// COMPONENTES
import Header from "components/Headers/Header.js";
import Grilla from "components/Grillas/Grilla.js";
import FieldsGroup from "components/FieldsGroup/FieldsGroup.js";
import Loading from "components/Loading/loading";

import ModalViewJSON from "components/Modals/ModalViewJSON.js";
import ModalsItinerarios from "./ModalsItinerarios.js";

import * as moment from 'moment';

class ConfigMaestrosExt extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      dataCombos : {clientes: []},
      form: {
        inpCliente: null
      },
      dataGrid: [],
      columnsGrid: [
        {caption: "Modo", dataField: "modeName", dataType: "string", width: "100", groupIndex: 0},
        {caption: "ID Ruta", dataField: "id", dataType: "string", width: "275"},
        {caption: "Codigo", dataField: "code", dataType: "string", width: "100", alignment: "center"},
        {caption: "Nombre", dataField: "name", dataType: "string", minWidth: "200"},
        {caption: "Activa", dataField: "active", dataType: "boolean", width: "90"},
      ],
      showLoading: false,
      rowData: [],
      listItinerariosByRoute: [],
      showModalViewJSON: false,
      showModalItinerarios: false,
    };
    this.modalViewJSONElement = React.createRef();
    this.modalItinerariosElement = React.createRef();
  }

  //#region  *********** REQUEST ***********

  getDataCombos = async () => {
    let user = getUser();

    let arr1 = await axios.post(urlAPI + "/mae_clientes/getAllBasic", { idCliente: user.idCliente }, configTokenAXI);
    arr1.data.data.map(e=> { e.id = e.empresaCorto});

    let rtn = {clientes: arr1.data.data};
    this.setState({ dataCombos: rtn });
  }
  
  getDataGrid = async () => {
    if(this.state.form.inpCliente == null){
      toastr.warn("¡Porfavor debe seleccionar un cliente!");
      return false;
    }

    this.setState({ showLoading: true });
    let dataGrid = [];

    let modes = await this.getDataExterna("getModes", { empresa: this.state.form.inpCliente, filterModeValid: true });
    if(!modes) this.setState({ showLoading: false });

    if(modes.length > 0){
      for (const item in modes) {
          let mode = modes[item];
          let routes = await this.getDataExterna("getRoutes", { empresa: this.state.form.inpCliente, modeId: mode.id });
          if(routes){
            routes = routes.map(e=>{return {...e, modeName: mode.description}})
            dataGrid.push(...routes);
          }
      }
    }

    this.setState({ showLoading: false, dataGrid: dataGrid });
  }

  getDataExterna = async (path, params) => {
    let rtn = await axios.post(urlAPI_SIBUS + "/external/" + path, params, configTokenSIBUS).catch(error => {
        let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
        toastr.error(res);
    });
    return (rtn && rtn.data?.stateOK?(rtn.data.data):rtn);
  }

  //#endregion

  handleChangeInputs = (e, nombreInput = null) => {
    let name = "";
    let value = "";
    
    if(typeof e == "boolean"){ // SWITCHS
      name = nombreInput;
      value = e;
    }else if(typeof e.target === "undefined"){ // SELECTS
      name = nombreInput;
      value = e.id;
    }else{
      if(hasContentVar(e.target.attributes.maxLength)){
        if(e.target.value.length > e.target.attributes.maxLength.value) return false;
      }
      name = e.target.name;
      value = e.target.value;
    }

    this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
    });
  }

  rowButtonView = async (item) => {
    let data = item.data;

    await this.setState({ 
      rowData: data,
      showModalViewJSON: true
    });
  }

  rowButtonItinerarios = async (item) => {
    let data = item.data;

    this.setState({ showLoading: true });

    let itineraries = await this.getDataExterna("getItineraries", { empresa: this.state.form.inpCliente, routeId: data.id });

    await this.setState({ 
      showLoading: false,
      rowData: data,
      listItinerariosByRoute: itineraries,
      showModalItinerarios: true
    });
    this.modalItinerariosElement.current.updateState();
  }

  closeModalViewJSON = () => {
    this.setState({ showModalViewJSON: false });
  };

  closeModalItinerarios = () => {
    this.setState({ showModalItinerarios: false });
  };

  componentDidMount = (e) => {
    this.getDataCombos();
  }

  render() {
    return (
      <>
        <Loading show={this.state.showLoading} />

        <Header />
        <Container className="mt--10" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody className="py-3">
                  <div className="py-2 mb-2">
                      <h5 className="heading-small text-muted m-0 text-center f-20 p-0"> CONSULTA DE MAESTROS EXTERNOS </h5>
                  </div>
                  <div className="container-fluid bg-style-2 py-2 px-3 rounded">
                    <Row className="py-1 pb-2">
                      <Col sm="4" md="3" className="px-2">
                        <FieldsGroup type="select" id="inpCliente" caption="Cliente" data={this.state.dataCombos.clientes} nameOptionSelect="empresa" value={this.state.form} attr={{isDisabled: this.state.blockCliente, onChange: e => this.handleChangeInputs(e, "inpCliente"), placeholder: "Seleccionar"}} />
                      </Col>
                      <Col sm="3" md="2">
                        <label className="form-control-label"></label>
                        <Button className="btn-icon btn-3 center-div mt-2 py-2 px-2 w-100 f-13" color="primary" type="button" onClick={() => this.getDataGrid()}>
                          <i className="fas fa-search mr-2"></i> Buscar
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <Grilla 
                    data={this.state.dataGrid} 
                    columns={this.state.columnsGrid}
                    columnsButtons={[
                      {cssClass: "text-muted mx-1 btn-grid-separator", hint: "Ver Objeto JSON", icon: "fas fa-eye", functionRtn: "fcButtonView"},
                      {cssClass: "color-primary mx-1 w-auto bg-blue-claro px-3 border-r-6 f-12-i hover-color-white hover-bg-primary transition-all-02", hint: "Ver Itinerarios", text: "Ver Itinerarios", functionRtn: "fcButtonItinerarios"},
                    ]}
                    columnsButtonsWidth={180}
                    fcButtonView={this.rowButtonView}
                    fcButtonItinerarios={this.rowButtonItinerarios}
                    height={310}
                    keyExpr="id"
                    groupExpandAll={true}
                  />
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        
        <ModalViewJSON
          ref={this.modalViewJSONElement}
          title={"RUTA"}
          subtitle={this.state.rowData?.name}
          dataJSON={this.state.rowData}
          show={this.state.showModalViewJSON} 
          fcClose={this.closeModalViewJSON}
        />

        <ModalsItinerarios
          ref={this.modalItinerariosElement}
          data={this.state.rowData}
          listItinerarios={this.state.listItinerariosByRoute}
          show={this.state.showModalItinerarios} 
          fcClose={this.closeModalItinerarios}
        />
        
      </>
    );
  }
}

export default ConfigMaestrosExt;
