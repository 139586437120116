import React from "react";
import { Button, Modal } from "reactstrap";
import ReactJson from 'react-json-view'

class ModalViewJSON extends React.Component {

  constructor(props) {
    super(props);
  }

  closeModal = () => {
    this.props.fcClose();
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.show} className="modal-dialog-centered modal-lg">
          <div className="modal-body py-3">
            <div className="px-lg-3">
              <label className="f-14 letter-spacing-2 m-0 opacity-6 text-center text-muted w-100">{this.props.title}</label>
              <h5 className="heading-small text-muted text-center f-16 mb-0 pt-0">{this.props.subtitle}</h5>
              <hr className="my-3" />
              <div>
                <ReactJson src={this.props.dataJSON} displayDataTypes={false} />
              </div>
            </div>
          </div>
          <div className="modal-footer pt-0">
            <Button color="light" className="btn-sm" data-dismiss="modal" type="button" onClick={this.closeModal} > Cerrar </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default ModalViewJSON;