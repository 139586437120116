
import Login from "views/Login.js";
import AudMovilidad from "views/aud_movilidad/AudMovilidad.js";
import AudBotonPanico from "views/aud_boton_panico/AudBotonPanico.js";
import AudDespachos from "views/aud_despachos/AudDespachos.js";
import AudVerificarMovilidad from "views/aud_verificar_movilidad/AudVerificarMovilidad.js";
import AudVerificarBloqueoTimbradas from "views/aud_verificar_bloqueo_timbradas/AudVerificarBloqueoTimbradas.js";
import MaeVehiculos from "views/mae_vehiculos/MaeVehiculos.js";
import MaeClientes from "views/mae_clientes/MaeClientes.js";
import ViewLogs from "views/view_logs/ViewLogs.js";
import ConfigMaestrosExt from "views/config_maestros_ext/ConfigMaestrosExt.js";
import Home from "views/home/Home.js";

var routes = [
  {
    name: "Inicio",
    path: "/home",
    icon: "ni ni-chart-pie-35 text-gray",
    component: Home,
    layout: "/admin",
    node: "DASHBOARD"
  },
  {
    name: "Clientes",
    path: "/mae_clientes",
    icon: "ni ni-building text-indigo opacity-7",
    component: MaeClientes,
    layout: "/admin",
    node: "MAESTROS"
  },
  {
    name: "Listado de Vehiculos",
    path: "/mae_vehiculos",
    icon: "ni ni-bus-front-12 text-orange",
    component: MaeVehiculos,
    layout: "/admin",
    node: "MAESTROS"
  },
  {
    name: "Aud. Movilidad",
    path: "/aud_movilidad",
    icon: "ni ni-square-pin text-blue",
    component: AudMovilidad,
    layout: "/admin",
    node: "CONSULTAS"
  },
  {
    name: "Aud. Boton Panico",
    path: "/aud_boton_panico",
    icon: "ni ni-support-16 text-danger",
    component: AudBotonPanico,
    layout: "/admin",
    node: "CONSULTAS"
  },
  {
    name: "Aud. Despachos",
    path: "/aud_despachos",
    icon: "ni ni-calendar-grid-58 text-gray-dark",
    component: AudDespachos,
    layout: "/admin",
    node: "CONSULTAS"
  },
  {
    name: "Verificar Movilidad",
    path: "/aud_verificar_movilidad",
    icon: "ni ni-bullet-list-67 text-info",
    component: AudVerificarMovilidad,
    layout: "/admin",
    node: "HERRAMIENTAS"
  },
  {
    name: "Verificar Bloqueo Tim.",
    path: "/aud_verificar_bloqueo_timbradas",
    icon: "ni ni-compass-04 text-green",
    component: AudVerificarBloqueoTimbradas,
    layout: "/admin",
    node: "HERRAMIENTAS"
  },
  {
    name: "Config. Maestros EXT",
    path: "/config_maestros_ext",
    icon: "ni ni-settings text-muted",
    component: ConfigMaestrosExt,
    layout: "/admin",
    node: "HERRAMIENTAS",
    onlyAdmin: true
  },
  {
    name: "Ver Logs",
    path: "/view_logs",
    icon: "ni ni-folder-17 text-purple",
    component: ViewLogs,
    layout: "/admin",
    node: "HERRAMIENTAS",
    onlyAdmin: true
  },
  {
    path: "/login",
    component: Login,
    layout: "/auth"
  }
];
export default routes;
