import React from "react";
import axios from 'axios';
import { Row, Col, Button, Modal, InputGroup, InputGroupText, Card, CardBody, CardImg, CardTitle, CardText, Badge, UncontrolledTooltip } from "reactstrap";
import { urlAPI, configTokenAXI, toastr, hasContentVar, currencyFormat } from "../../variables/utils";

class ModalsFinalizar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {
        id: null
      }
    }
  }

  updateState = () => {
    let { data } = this.props;
    this.setState({
      form: {
        id: data.id
      }
    });
  }

  closeModal = () => {
      this.props.fcClose();
  }

  aceptarModal = () => {
      this.props.fcAceptar();
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.show} className="modal-dialog-centered modal-md">
          <div className="modal-body py-3">
            <div className="px-lg-4">
              <h5 className="heading-small text-muted text-center py-3 f-18 mb-0"> Finalizar Despacho <b><i>N°{this.state.form.id}</i></b><br/>
                  <small className="text-transform-none">Desea finalizar este despacho?</small>
              </h5>
            </div>
          </div>
          <div className="modal-footer pt-0">
            <Button color="danger" className="btn-sm" type="button" onClick={this.aceptarModal} disabled={this.state.form.id == null}> Finalizar </Button>
            <Button color="light" className="btn-sm" data-dismiss="modal" type="button" onClick={this.closeModal} > Cancelar </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default ModalsFinalizar;