import React from "react";
import { Button, Modal } from "reactstrap";
import { urlAPI_SIBUS, configTokenSIBUS, toastr } from "../../variables/utils";
import { LazyLog } from 'react-lazylog';

import FieldsGroup from "components/FieldsGroup/FieldsGroup.js";

class ModalsViewFile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {
        inpCheckRefresh: false,
      },
      key: 0,
      segIntervalUpdate: 120,
      segLeftUpdate: 120,
    };
    this.timer = null;
  }

  modalMount = () => {
    this.stopRefreshInterval();
  }

  runRefreshInterval = () => {
    this.clearRefreshInterval();

    this.timer = setInterval(() => {
      if(this.state.segLeftUpdate <= 1){
        this.setState((prevState) => ({
          key: prevState.key + 1,
          segLeftUpdate: prevState.segIntervalUpdate,
        }));
      }else{
        this.setState((prevState) => ({
          segLeftUpdate: prevState.segLeftUpdate - 1,
        }));  
      }
    }, 1000);
  }

  stopRefreshInterval = () => {
    this.setState((prevState) => ({
      form: {
        ...this.state.form,
        inpCheckRefresh: false,
      }
    }), () => {
      this.clearRefreshInterval();
    });  
  }

  clearRefreshInterval = () => {
    clearInterval(this.timer);
    this.timer = null;

    this.setState((prevState) => ({
      key: 0,
      segLeftUpdate: prevState.segIntervalUpdate,
    }));
  }

  handleChangeInputs = (e, nombreInput = null) => {
    let name = "";
    let value = "";
    
    if(typeof e == "boolean"){ // SWITCHS
      name = nombreInput;
      value = e;
    }else if(typeof e.target === "undefined"){ // SELECTS
      name = nombreInput;
      value = e.id;
    }else{
      if(hasContentVar(e.target.attributes.maxLength)){
        if(e.target.value.length > e.target.attributes.maxLength.value) return false;
      }
      name = e.target.name;
      value = e.target.value;
    }

    this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
    }, () => {
      if(name == "inpCheckRefresh"){
        if(value){
          this.runRefreshInterval();
        }else{
          this.clearRefreshInterval();
        }
      }
    });
  }

  closeModal = () => {
    this.stopRefreshInterval();
    this.props.fcClose();
  }

  componentWillUnmount() {
    this.stopRefreshInterval();
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.show} className="modal-dialog-centered modal-full">
          <div className="modal-body py-3">
            <div className="px-lg-3">
              <label className="f-14 letter-spacing-2 m-0 opacity-6 text-center text-muted w-100">Archivo</label>
              <h5 className="heading-small text-muted text-center f-16 mb-0 pt-0">{this.props.nameFile}</h5>
              <hr className="my-3" />
              <div style={{width: "100%", height: "600px"}}>
                <LazyLog key={this.state.key} url={urlAPI_SIBUS + "/internal/viewFileLog?file=" + this.props.nameFile} 
                        fetchOptions={configTokenSIBUS} enableSearch={true} extraLines={3} rowHeight={23} selectableLines={true}  />
              </div>
            </div>
          </div>
          <div className="modal-footer pt-0 justify-content-between">
            <div className="d-flex ml-3">
              <FieldsGroup className="" type="switch" id="inpCheckRefresh" showLabel={false} withMargin={false} value={this.state.form} fcHandleChange={e => this.handleChangeInputs(e, "inpCheckRefresh")} attr={{width: 35, height: 18}} />
              <label className="m-0 ml-2 font-13 text-muted">
                {this.state.form.inpCheckRefresh && <span><i className="fas fa-sync color-blue mr-2 font-12"></i>Se refrescara el archivo en <b>{this.state.segLeftUpdate}</b> segundos ...</span>}
                {!this.state.form.inpCheckRefresh && <span><i className="fas fa-sync color-blue mr-2 font-12"></i>Refrescar el archivo automaticamente</span>}
              </label>
            </div>          
            <Button color="light" className="btn-sm" data-dismiss="modal" type="button" onClick={this.closeModal} > Cerrar </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default ModalsViewFile;