import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import 'react-toastify/dist/ReactToastify.css';
import "assets/css/app.css";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import { getToken } from "./variables/utils";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={ (props) => getToken() ? <AdminLayout {...props} /> : <Redirect from="*" to="/auth" /> } />
      <Route path="/auth" render={ (props) => !getToken() ? <AuthLayout {...props} /> : <Redirect from="*" to="/admin" /> } />
      <Redirect from="/" to="/auth" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
