import React from "react";
import axios from 'axios';
import { Card, CardBody, Container, Row, Button } from "reactstrap";
import 'jspdf-autotable';
import { urlAPI, configTokenAXI, toastr, getUser, hasContentVar, capitalizeFormat, replaceCamposEstadoFormat } from "../../variables/utils";
// COMPONENTES
import Header from "components/Headers/Header.js";
import Grilla from "components/Grillas/Grilla.js";
import Loading from "components/Loading/loading";

class MaeVehiculos extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      dataGrid: [],
      dataGridRaw: [],
      columnsGrid: [
        {caption: "-", dataField: "a", dataType: "string", minWidth: "100"}
      ],
      listRowsChanged: [],
      showLoading: false,
    };
  }

  //#region  *********** REQUEST ***********

  getDataGrid = () => {
    let user = getUser();

    this.setState({ showLoading: true });
    
    axios.post(urlAPI + "/mae_vehiculos/getAll", { idCliente: user.idCliente }, configTokenAXI)
    .then(response => {
        this.renderGrid(response.data.data);

    }).catch(error => {
        this.setState({ showLoading: false });

        let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
        toastr.error(res);
    });
  }

  submitData = () => {
    let user = getUser();
    let data = JSON.stringify(this.state.listRowsChanged);
    let dataClientes = JSON.stringify(this.state.listRowsChanged.map(e=>e.codigoEmpresa));

    axios.post(urlAPI + "/mae_vehiculos/updateMasivo", {idCliente: user.idCliente, idUsuario: user.id, data, dataClientes}, configTokenAXI)
      .then(response => {
          if(response.data.stateOK){
            toastr.success(response.data.message);
            this.getDataGrid();
          }else{
            toastr.error(response.data.message);
          }
      }).catch(error => {
          let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
          toastr.error(res);
      })
  }

  //#endregion

  
  //#region  *********** EVENTS ***********

  handleChangeInputs = (e, nombreInput = null) => {
    let name = "";
    let value = "";
    
    if(typeof e.target === "undefined"){ // SELECTS
      name = nombreInput;
      value = e.id;
    }else{
      if(hasContentVar(e.target.attributes.maxLength)){
        if(e.target.value.length > e.target.attributes.maxLength.value) return false;
      }
      name = e.target.name;
      value = e.target.value;
    }

    this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
    })
  }

  renderGrid = (data) => {
    if(data.length > 0){
      let columns = [];

      // CREO LAS COLUMNAS DE LA GRILLA DINAMICAMENTE
      let keys = Object.keys(data[0]);

      let columnsBasic = [];
      let columnsChildrens = [];
      keys.forEach((key) => {
        if(key == "id") return null;
        let caption = capitalizeFormat(key.replaceAll("_", " "));
        if(key.includes("estado_envio_")){
          caption = replaceCamposEstadoFormat(caption);
          columnsChildrens.push({caption: caption, dataField: key, dataType: "boolean", width: "170", alignment: "center"});
        }else{
          columnsBasic.push({caption: caption, dataField: key, dataType: "string", width: "100", allowEditing: false, visible: (caption == "CodigoEmpresa"?false:true)});
        }
      });

      let idx_placa = columnsBasic.findIndex(e => e.dataField == "placa");
      columnsBasic[idx_placa].minWidth = "100";
      delete columnsBasic[idx_placa].width;

      columns = [...columnsBasic, {
        caption: "Configura los valores en las siguientes columnas",
        childrensColumn: columnsChildrens
      }];

      // FORMATEO LOS VALORES DE 1 y 0 A True y False
      data.map((value) => {
        let keys = Object.keys(value);
        keys.map((key) => {
          if(key.includes("estado_envio_")) value[key] = (value[key] == 1?true:false)
        })
      });

      this.setState({ showLoading: false, columnsGrid: columns, dataGrid: [...data], dataGridRaw: JSON.stringify(data) });
    }else{
      this.setState({ showLoading: false });
    }
  }

  savedChanges = (e) => {
    let row = {...e.changes[0].data};

    let listChanges = this.state.listRowsChanged;
    let findChange = listChanges.find(e => e.id == row.id);

    let dataRaw = JSON.parse(this.state.dataGridRaw);
    let findRaw = dataRaw.find(e => e.id == row.id);

    if(findChange) listChanges = listChanges.filter(e=>e.id != row.id);

    if(JSON.stringify(findRaw) !== JSON.stringify(row)) listChanges.push(row);

    this.setState({ listRowsChanged: listChanges });
  }

  clickButtonGuardar = () => {
    if(this.state.listRowsChanged.length <= 0) {
      toastr.warn("Debe realizar cambios para poder guardar");
      return false
    }
    this.submitData();
  }
  
  //#endregion

  componentDidMount = (e) => {
    this.getDataGrid();
  }

  render() {
    return (
      <>
        <Loading show={this.state.showLoading} />

        <Header />
        <Container className="mt--10" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody className="py-3">
                  <div className="py-2 mb-2">
                    <h5 className="heading-small text-muted m-0 text-center f-20 p-0"> LISTADO DE VEHICULOS </h5>
                  </div>
                  <Grilla 
                    data={this.state.dataGrid} 
                    columns={this.state.columnsGrid}
                    height={250}
                    keyExpr="id"
                    fcSavedChanges={this.savedChanges}
                    allowEdit={true}
                  />
                  <Button className="btn-icon btn-3 center-div mt-3 mb-1 py-1 px-2 pr-3 float-right" color="success" type="button" onClick={() => this.clickButtonGuardar()} disabled={this.state.listRowsChanged.length==0}>
                    <i className="ni ni-check-bold f-14 mx-2" /> Guardar Cambios { this.state.listRowsChanged.length > 0 && <small className="ml-1 font-italic"> ({this.state.listRowsChanged.length} Vehiculos)</small> }
                  </Button>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
    
      </>
    );
  }
}

export default MaeVehiculos;
