import DotLoader from "react-spinners/DotLoader";

function Loading(props) {
  return (
    <div className="sweet-loading">
        <div>
            <DotLoader color="#1DB4E9" loading={props.show} size={50} aria-label="Loading Spinner" />
        </div>
    </div>
  );
}

export default Loading;