import React from "react";
import { Link } from "react-router-dom";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Navbar, Nav, Container, Media } from "reactstrap";

import { getUser, removeUserSession } from "../../variables/utils";

//ASSETS
import userIMG from "assets/img/theme/team-4-800x800.jpg";

class Navbars extends React.Component {

  logout = () => {
    removeUserSession();
    window.location.href = "/auth";
  }

  render() {
    const user = getUser();

    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link className="h4 mb-0 text-white text-uppercase d-none" to="/">
              {this.props.brandText}
            </Link>
            <Form className="navbar-search form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative bg-white border-0">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Buscar" type="text" className="h-34"/>
                </InputGroup>
              </FormGroup>
            </Form>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle shadow-3" style={{ border: "5px solid white", marginRight: "-4px", zIndex: "2" }}>
                      <img alt="USER IMG" src={userIMG} />
                    </span>
                    <Media className="d-none d-lg-block">
                      <span className="bg-white font-weight-bold mb-0 p-1 px-3 rounded-lg rounded-right shadow-3 text-black-50 text-sm text-uppercase rounded-lg">
                        {user.nombre} <small className="font-weight-600">({user.empresa})</small>
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title py-0" header tag="div">
                    <h6 className="text-center m-0 f-13"><span className="text-muted text-center f-9">Usuario:</span><br/>{user.nombre} <small>({user.empresa})</small></h6>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="#" onClick={this.logout}>
                    <i className="ni ni-button-power" />
                    <span>Salir</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default Navbars;
