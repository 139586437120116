import React from "react";
import axios from 'axios';
import { 
  Button, Card, CardBody, FormGroup, Input, 
  InputGroupAddon, InputGroupText, InputGroup, Col
} from "reactstrap";

import { urlAPI, setUserSession, toastr } from "../variables/utils";

import logoIMG from "assets/img/logo.png";

class Login extends React.Component {

  state = {
    form: {
      username: "",
      password: ""
    }
  }

  handleChange = (e) => {
    this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value
        }
    })
  }

  login = () => {
    if(this.state.form.username == "" || this.state.form.password == "") {
      toastr.warn("Digite los datos!");
      return false;
    }

    const params = {
      cedula: this.state.form.username,
      pass: this.state.form.password
    };
    axios.post(urlAPI + "/login", params)
      .then(response => {
          let res = response.data;
          setUserSession(res.data.token, res.data.userData);
          window.location.href = "/admin";
      }).catch(error => {
        let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
        toastr.error(res);
      })
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-white shadow-lg-2">
            <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center mb-5">
                  <img alt="LOGO LM" src={logoIMG} style={{width: "110px"}} />
                  <br />
                  <label className="m-0 font-25"><b className="color-blue">LM</b>SOLUCIONES</label>
                </div>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Cedula" className="px-3 f-16" type="number" name="username" onChange={this.handleChange} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Contraseña" className="px-3 f-16" type="password" name="password" onChange={this.handleChange} />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="mt-4 w-50" color="primary" type="button" onClick={this.login}> Ingresar </Button>
                </div>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Login;
