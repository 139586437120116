import { toast } from 'react-toastify';
import {ExcelJS, Workbook} from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';

export const urlAPI = process.env.REACT_APP_API_URL;
export const urlAPI_SIBUS = process.env.REACT_APP_API_SIBUS_URL;

export const getUser = () => {
  const userStr = localStorage.getItem('user_lm_AGS');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

export const getToken = () => {
  return localStorage.getItem('token_lm_AGS') || null;
}

export const removeUserSession = () => {
  localStorage.removeItem('token_lm_AGS');
  localStorage.removeItem('user_lm_AGS');
}

export const setUserSession = (token, user) => {
  localStorage.setItem('token_lm_AGS', token);
  localStorage.setItem('user_lm_AGS', JSON.stringify(user));
}

/* ****************************** */

export const configTokenDEV = (options) => {
  options.onBeforeSend = function(method, ajaxOptions) {  
      ajaxOptions.headers = {  
        "Authorization": localStorage.getItem('token_lm_AGS')
      }  
  }  
  return options;  
}

export const configTokenAXI = {
    headers: {
      'Authorization': localStorage.getItem('token_lm_AGS')
    }
}

export const configTokenSIBUS = {
  headers: {
    'lm_key_api': "3nC5YcT4cY0NKei#"
  }
}

/* ****************************** */

export const getDateNow = () => {
  return moment(new Date()).format("YYYY-MM-DD");
}

export const hasContentVar = (str) => {
  return (typeof str === "undefined" || str == null)?false:true;
}

export const filterValueSelect = (opts, id) => {
  if(id == "N/A"){
    return null;
  }else{
    return opts.find(o => o.id === id);
  }
}

export const changeKeysOptionsSelect = (data, nameLabel) => {
  data.forEach((element, i) => {
      if(typeof data[i][nameLabel] !== "undefined" && typeof data[i]["id"] !== "undefined"){
        Object.defineProperty(data[i], "label", Object.getOwnPropertyDescriptor(data[i], nameLabel));
        Object.defineProperty(data[i], "value", Object.getOwnPropertyDescriptor(data[i], "id"));
        delete data[i][nameLabel];
        delete data[i]["id"];
      }
  });
  return data;
}

String.prototype.toCapitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

export const currencyFormat = (num) => {
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const decimalFormat = (num) => {
  return parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const capitalizeFormat = (str) => {
  let words = str.split(' ');
  let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  let result = capitalizedWords.join(' ');
  return result;
}

export const replaceCamposEstadoFormat = (str) => {
  return (str.replaceAll("Estado", "").replaceAll("Ambq", "").replaceAll("Despacho End", "Finalizacion")).trim();
}

export const uniqueArrayObjs = (arr) => {
  let array = arr;
  return arr.filter((value, index) => {
    const _value = JSON.stringify(value);
    return index === array.findIndex(obj => { return JSON.stringify(obj) === _value });
  });
}

export const importExcel = async(file) => {
  let data = [];
  const workbook = new Workbook();
  await workbook.xlsx.load(file);
  workbook.worksheets[0].eachRow(function (row, rowNumber) {
    let valuesRow = [];
    if (rowNumber != 1) {
      row.eachCell({ includeEmpty: true }, function(cell, colNumber) {
        valuesRow.push(cell.value);
      });
      data.push(valuesRow);
    }
  });
  return data;
}

export const importExcelJSON = async(file) => {
  let headers = [];
  let data = [];
  const workbook = new Workbook();
  await workbook.xlsx.load(file);
  workbook.worksheets[0].eachRow(function (row, rowNumber) {
    if (rowNumber == 1) {
      row.eachCell({ includeEmpty: true }, function(cell, colNumber) {
        headers.push(cell.value.toUpperCase());
      });
    }else{
      let valuesRow = {};
      row.eachCell({ includeEmpty: true }, function(cell, colNumber) {
        valuesRow[headers[colNumber-1]] = cell.value;
      });
      data.push(valuesRow);
    }
  });
  return data;
}

export const generarPlantilla = async (headers, rows, nameReport) => {
  const workbook = new Workbook();
  let sheet = workbook.addWorksheet("LIBRO");

  let rowHeader = sheet.addRow(headers);
  rowHeader.eachCell(function(cell) {
    // STYLES
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
    cell.fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'DCE6F1'} };
    cell.border = { bottom: {style:'thin'} };
  });

  rows.map((e, i) => {
    let row = sheet.addRow(e);
    row.font = {color: {argb: 'D5DBDB'}};
  });

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, nameReport + '.xlsx');
  })
}

/* ****************************** */

export const toastr = {
  toastConfig: {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
  success: function(text = "Accion realizada satisfactoriamente!") {
    toast.success('😃 '+ text, this.toastConfig);
  },
  info: function(text) {
    toast.info('🤔 '+ text, this.toastConfig);
  },
  warn: function(text) {
    toast.warn('⚠️ '+ text, this.toastConfig);
  },
  error: function(text = "Ooops, ha ocurrido un error!") {
    toast.error('⛔ '+ text, this.toastConfig);
  },
  basic: function(text = "Accion realizada satisfactoriamente!") {
    toast('🚀 '+ text, this.toastConfig);
  },
}