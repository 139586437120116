import React from "react";
import axios from 'axios';
import { Card, CardBody, Container, Row } from "reactstrap";
import 'jspdf-autotable';
import { urlAPI, configTokenAXI, toastr, getUser, capitalizeFormat, replaceCamposEstadoFormat } from "../../variables/utils";
// COMPONENTES
import Header from "components/Headers/Header.js";
import Grilla from "components/Grillas/Grilla.js";
import Loading from "components/Loading/loading";

import Modals from "./Modals";

class MaeClientes extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      dataGrid: [],
      columnsGrid: [
        {caption: "-", dataField: "a", dataType: "string", minWidth: "100"}
      ],
      rowData: [],
      showModal: false,
      showLoading: false,
      tipoQuery: ""
    };
    this.modalElement = React.createRef();
  }

  //#region  *********** REQUEST ***********

  getDataGrid = () => {
    let user = getUser();

    this.setState({ showLoading: true });
    
    axios.post(urlAPI + "/mae_clientes/getAll", { idCliente: user.idCliente }, configTokenAXI)
    .then(response => {
        this.renderGrid(response.data.data);

    }).catch(error => {
        this.setState({ showLoading: false });

        let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
        toastr.error(res);
    });
  }

  submitData = (data) => {
    let user = getUser();

    axios.post(urlAPI + "/mae_clientes/update", {idCliente: user.idCliente, idUsuario: user.id, data: JSON.stringify(data)}, configTokenAXI)
      .then(response => {
          if(response.data.stateOK){
            toastr.success(response.data.message);
            this.closeModal();
            this.getDataGrid();
          }else{
            toastr.error(response.data.message);
          }
      }).catch(error => {
          let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
          toastr.error(res);
      })
  }

  //#endregion

  
  //#region  *********** EVENTS ***********

  renderGrid = (data) => {
    if(data.length > 0){
      let columns = [];

      // CREO LAS COLUMNAS DE LA GRILLA DINAMICAMENTE
      let keys = Object.keys(data[0]);

      keys.forEach((key) => {
        if(key == "id") return null;
        let caption = capitalizeFormat(key.replaceAll("_", " "));
        if(key.includes("estado_envio_")){
          caption = replaceCamposEstadoFormat(caption);
          columns.push({caption: caption, dataField: key, dataType: "boolean", width: "170", alignment: "center"});
        }else{
          caption = caption.replaceAll("EmpresaCorto", "Siglas");
          columns.push({caption: caption, dataField: key, dataType: "string", width: "100", visible: (caption == "Codigo"?false:true)});
        }
      });

      let idx_ = columns.findIndex(e => e.dataField == "empresa");
      columns[idx_].minWidth = "200";
      delete columns[idx_].width;

      this.setState({ showLoading: false, columnsGrid: columns, dataGrid: [...data] });
    }else{
      this.setState({ showLoading: false});
    }
  }

  rowDblClick = (e) => {
    let data = e.data;
    this.showModal("E", data);
  }

  showModal = async (tipo, data = []) => {
    await this.setState({ 
      showModal: true, 
      tipoQuery: tipo,
      rowData: data
    });
    this.modalElement.current.updateState();
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  aceptarModal = (data) => {
    this.submitData(data);
  };

  //#endregion

  componentDidMount = (e) => {
    this.getDataGrid();
  }

  render() {
    return (
      <>
        <Loading show={this.state.showLoading} />

        <Header />
        <Container className="mt--10" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody className="py-3">
                  <div className="py-2 rounded">
                    <h5 className="modal-title heading-small text-muted text-center f-20"> MAESTRO DE CLIENTES </h5>
                    <h6 className="text-muted heading-small text-center subtitles"> ( Pulse doble click en las filas para editar ) </h6>
                  </div>
                  <Grilla 
                    data={this.state.dataGrid} 
                    columns={this.state.columnsGrid}
                    fcRowDblClick={this.rowDblClick}
                    height={227}
                    keyExpr="id"
                  />
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>

        <Modals 
          ref={this.modalElement}
          data={this.state.rowData}
          show={this.state.showModal} 
          fcClose={this.closeModal}  
          fcAceptar={this.aceptarModal}  
        />
        
      </>
    );
  }
}

export default MaeClientes;
