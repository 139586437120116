import React from "react";
import axios from 'axios';
import { Card, CardBody, Container, Row, Button, Col } from "reactstrap";
import 'jspdf-autotable';
import { urlAPI, urlAPI_SIBUS, configTokenAXI, configTokenSIBUS, toastr, getUser, getDateNow, hasContentVar } from "../../variables/utils";
// COMPONENTES
import Header from "components/Headers/Header.js";
import Grilla from "components/Grillas/Grilla.js";
import FieldsGroup from "components/FieldsGroup/FieldsGroup.js";
import Loading from "components/Loading/loading";
import * as moment from 'moment';

import ModalsFinalizar from "./ModalsFinalizar.js";
import ModalsDetalleMov from "./ModalsDetalleMov.js";

class AudDespachos extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      form: {
        inpFecha1: getDateNow(),
        inpFecha2: getDateNow(),
      },
      dataGrid: [],
      columnsGrid: [
        {caption: "Finalizado", dataField: "isFinalizado", dataType: "boolean", width: "130", alignment: "center"},
        {caption: "N° Registro", dataField: "id", dataType: "string", width: "110", allowHeaderFiltering: false},
        {caption: "Cliente", dataField: "empresa", dataType: "string", width: "90"},
        {caption: "N° Viaje", dataField: "idViaje", dataType: "string", width: "90", allowHeaderFiltering: false},
        {caption: "Ruta", dataField: "idRuta", dataType: "string", width: "230"},
        {caption: "Itinerario", dataField: "idItinerario", dataType: "string", width: "230"},
        {caption: "Vehiculo", dataField: "placaVehi", dataType: "string", width: "90"},
        {caption: "Ced.Conductor", dataField: "cedConductor", dataType: "string", width: "140"},
        {caption: "Hora Despacho", dataField: "horaDespacho", dataType: "datetime", format: 'dd/MM/yyyy HH:mm:ss', width: "150", allowHeaderFiltering: false},
        {caption: "Hora Llegada", dataField: "horaFin", dataType: "datetime", format: 'dd/MM/yyyy HH:mm:ss', width: "150", allowHeaderFiltering: false},
        {caption: "Tot.Subidas", dataField: "totSub", dataType: "string", width: "120", alignment: "center", allowHeaderFiltering: false},
        {caption: "Tot.Bajadas", dataField: "totBaj", dataType: "string", width: "120", alignment: "center", allowHeaderFiltering: false},
        {caption: "Latitud", dataField: "lat", dataType: "string", width: "110", allowHeaderFiltering: false},
        {caption: "Longitud", dataField: "lng", dataType: "string", width: "110", allowHeaderFiltering: false},
        {caption: "Fecha Sistema", dataField: "fecha_accion", dataType: "datetime", format: 'dd/MM/yyyy HH:mm:ss', minWidth: "150", allowHeaderFiltering: false},
      ],
      disabledFinalizar: true,
      rowDataFinalizar: [],
      rowDataDetalleMov: [],
      showModalFinalizar: false,
      showModalDetalleMov: false,
      showLoading: false,
    };
    this.modalFinalizarElement = React.createRef();
    this.modalDetalleMovElement = React.createRef();
  }

  //#region  *********** REQUEST ***********

  getDataGrid = () => {
    let user = getUser();
    if(this.state.form.inpFecha1 == "" || this.state.form.inpFecha2 == ""){
      toastr.warn("¡Porfavor verificar las fechas ingresadas!");
      return false;
    }

    this.setState({ showLoading: true });
    
    axios.post(urlAPI + "/aud_despachos/getAllByFechas", { idCliente: user.idCliente, f1: this.state.form.inpFecha1, f2: this.state.form.inpFecha2 }, configTokenAXI)
    .then(response => {
        this.setState({ showLoading: false, dataGrid: response.data.data });

    }).catch(error => {
        this.setState({ showLoading: false });

        let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
        toastr.error(res);
    });
  }

  getDataFinalizarDespacho = () => {
    let data = this.state.rowDataFinalizar;
    axios.post(urlAPI + "/aud_despachos/getDataEndDespacho", {idCliente: data.idCliente, empresa: data.empresa, placa: data.placaVehi}, configTokenAXI)
      .then(response => {
          let res = response.data.data;
          if(res.length == 0){
            toastr.warn("El despacho no se puede finalizar, la empresa o el vehiculo no esta configurado para el envio!");
            this.closeModalFinalizar();
            return false;
          }
          this.finalizarDespacho(res[0]);
      }).catch(error => {
          let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
          toastr.error(res);
      })
  }

  finalizarDespacho = (dataRes) => {
    let data = this.state.rowDataFinalizar;
    axios.post(urlAPI_SIBUS + "/external/endDespacho", {
        idViaje: data.idCliente+"-"+data.idViaje, 
        horaFin: dataRes.fechaMilisegundos, 
        sub: dataRes.subidas,
        baj: dataRes.bajadas,
        empresa: data.empresa
      }, configTokenSIBUS)
      .then(response => {
          let res = response.data.data;
          toastr.success(res.message);
          this.closeModalFinalizar();
          this.getDataGrid();
      }).catch(error => {
          let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
          toastr.error(res);
      })
  }

  //#endregion

  
  //#region  *********** EVENTS ***********

  handleChangeInputs = (e, nombreInput = null) => {
    let name = "";
    let value = "";
    
    if(typeof e.target === "undefined"){ // SELECTS
      name = nombreInput;
      value = e.id;
    }else{
      if(hasContentVar(e.target.attributes.maxLength)){
        if(e.target.value.length > e.target.attributes.maxLength.value) return false;
      }
      name = e.target.name;
      value = e.target.value;
    }

    this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
    })
  }

  rowClick = (e) => {
    let data = e.data;
    let disabled = (data.isFinalizado==1?true:false);
    this.setState({ 
      rowDataFinalizar: data,
      disabledFinalizar: disabled
    });
  }

  rowDoubleClick = async (e) => {
    let data = e.data;
    if(data.horaFin == null) {
      toastr.warn("El viaje debe tener (Hora Llegada) para consultar el detallado de movilidad");
      return false;
    }

    let temp = {
      idCliente: data.idCliente,
      empresa: data.empresa,
      placaVehi: data.placaVehi,
      fechaHora1: moment(data.horaDespacho).format("YYYY-MM-DD HH:mm:ss"),
      fechaHora2: moment(data.horaFin).format("YYYY-MM-DD HH:mm:ss"),
    };
    await this.setState({ rowDataDetalleMov: temp, showModalDetalleMov: true });
    this.modalDetalleMovElement.current.updateState();
  }

  clickButtonFinalizar = () => {
    if(this.state.rowDataFinalizar.isFinalizado != 0) {
      toastr.warn("Solo puede finalizar despachos pendientes");
      return false
    }
    this.showModalFinalizar();
  }

  showModalFinalizar = async () => {
    await this.setState({ showModalFinalizar: true });
    this.modalFinalizarElement.current.updateState();
  };

  closeModalFinalizar = () => {
    this.setState({ showModalFinalizar: false });
  };

  closeModalDetalleMov = () => {
    this.setState({ showModalDetalleMov: false });
  };

  aceptarModalFinalizar = () => {
    this.getDataFinalizarDespacho();
  };

  //#endregion

  componentDidMount = (e) => {
    this.getDataGrid();
  }

  render() {
    return (
      <>
        <Loading show={this.state.showLoading} />

        <Header />
        <Container className="mt--10" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody className="py-3">
                  <div className="py-2 mb-2">
                    <h5 className="heading-small text-muted m-0 text-center f-20 p-0"> HISTORIAL ENVIO DE DESPACHOS </h5>
                  </div>
                  <div className="container-fluid bg-style-2 py-2 px-3 rounded">
                    <Row className="py-1 pb-2">
                      <Col sm="3" md="2" className="px-2">
                      <FieldsGroup type="date" caption="Fecha Inicial:" id="inpFecha1" value={this.state.form} attr={{onChange: e => this.handleChangeInputs(e) }} />
                      </Col>
                      <Col sm="3" md="2" className="px-2">
                        <FieldsGroup type="date" caption="Fecha Final:" id="inpFecha2" value={this.state.form} attr={{onChange: e => this.handleChangeInputs(e) }}/>
                      </Col>
                      <Col sm="3" md="2">
                        <label className="form-control-label"></label>
                        <Button className="btn-icon btn-3 center-div mt-2 py-2 px-2 w-100 f-13" color="primary" type="button" onClick={() => this.getDataGrid()}>
                          <i className="fas fa-search mr-2"></i> Buscar
                        </Button>
                      </Col>
                      <Col sm="3" md="6" className="center-div-y justify-content-end pr-0">
                        <label className="f-13 text-muted opacity-08 w-75 m-0 text-right">* Doble click sobre un registro para ver el detallado de los registros de movilidad del viaje</label>
                      </Col>
                    </Row>
                  </div>
                  <Grilla 
                    data={this.state.dataGrid} 
                    columns={this.state.columnsGrid}
                    height={360}
                    keyExpr="id"
                    fcRowClick={this.rowClick}
                    fcRowDblClick={this.rowDoubleClick}
                  />
                  <Button className="btn-icon btn-3 center-div mt-3 mb-1 py-1 px-2 pr-3 float-right" color="danger" type="button" onClick={() => this.clickButtonFinalizar()} disabled={this.state.disabledFinalizar}>
                    <i className="ni ni-curved-next f-14 mx-2" /> Finalizar Despacho
                  </Button>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>

        <ModalsFinalizar
          ref={this.modalFinalizarElement}
          data={this.state.rowDataFinalizar}
          show={this.state.showModalFinalizar} 
          fcClose={this.closeModalFinalizar}  
          fcAceptar={this.aceptarModalFinalizar}  
        />

        <ModalsDetalleMov
          ref={this.modalDetalleMovElement}
          data={this.state.rowDataDetalleMov}
          show={this.state.showModalDetalleMov} 
          fcClose={this.closeModalDetalleMov}
        />
    
      </>
    );
  }
}

export default AudDespachos;
