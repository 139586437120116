import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer } from 'react-toastify';

import routes from "routes.js";

class Auth extends React.Component {

  componentDidMount() {
    document.body.classList.add("bg-secondary");
  }

  componentWillUnmount() {
    document.body.classList.remove("bg-secondary");
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
        if (prop.layout === "/auth") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return null;
        }
    });
  };

  render() {
    return (
      <>
        <div className="main-content">
          <div className="header bg-gradient-info py-7 py-lg-6">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <h1 className="text-white m-0 text-shadow display-3">Bienvenido!</h1>
                    <p className="f-18 font-bold color-blue-oscuro mb-0 mb-lg-4 px-5">Sistema para <i>Consulta</i> y <i>Auditoria</i> de datos externos</p>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0" >
                <polygon className="fill-secondary" points="2560 0 2560 100 0 100" />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="*" to="/auth/login" />
              </Switch>
            </Row>
          </Container>
          <ToastContainer />
        </div>
      </>
    );
  }
}

export default Auth;
