import React from "react";
import DataGrid, { Column, Grouping, GroupPanel, Editing, Pager, Paging, SearchPanel, FilterRow, Summary, TotalItem, Export, Button, HeaderFilter, GroupItem, KeyboardNavigation } from 'devextreme-react/data-grid';
import locationES from "../../assets/others/devExtremeSpanish.json";
import { locale, loadMessages } from "devextreme/localization";
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { hasContentVar } from "../../variables/utils";

class Grilla extends React.Component {

  constructor(props) {
      super(props);
      loadMessages(locationES);
      locale("es");
      this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
      this.dataGridRef = React.createRef();
  }
  
  renderButtons = () => {
    if(hasContentVar(this.props.columnsButtons)){
      return(
        <Column type="buttons" width={(hasContentVar(this.props.columnsButtonsWidth)?this.props.columnsButtonsWidth:(40*this.props.columnsButtons.length))}>
          {
            this.props.columnsButtons.map((value, i) => {
              return (
                <Button key={i} {...value} onClick={(e) => this.buttonCellClick(e, value.functionRtn)}/>
              );
            })
          }
        </Column>
      );
    }
  }

  renderTotales = () => {
    const calculateCustomSummary = (options) => {
      if(options.name == "margenConsolidadoGanancias") {
          switch(options.summaryProcess) {
              case "start":
                  options.totalValue = 0;
                  options.total_cli = 0;
                  options.total_ope = 0;
                  break;
              case "calculate":
                  options.total_cli += parseInt(options.value.facturado_cli);
                  options.total_ope += parseInt(options.value.facturado_ope);
                  break;
              case "finalize":
                  options.totalValue = (options.total_cli == 0?0:(((options.total_cli-options.total_ope)/options.total_cli)*100).toFixed(1));
                  break;
          }
      }
    }

    if(hasContentVar(this.props.columnsTotales) || hasContentVar(this.props.groupsTotales)){
      return(
        <Summary calculateCustomSummary={calculateCustomSummary}>
          { hasContentVar(this.props.columnsTotales) &&
            this.props.columnsTotales.map((value, i) => {
              return (
                <TotalItem key={i} {...value} />
              );
            })
          }
          { hasContentVar(this.props.groupsTotales) &&
            this.props.groupsTotales.map((value, i) => {
              return (
                <GroupItem key={i} {...value} />
              );
            })
          }
        </Summary>
      );
    }
  }

  rowClick = (e) => {
    if(typeof this.props.fcRowClick === "function"){
      this.props.fcRowClick(e);
    }
  }

  rowDblClick = (e) => {
      if(typeof this.props.fcRowDblClick === "function"){
        this.props.fcRowDblClick(e);
      }  
  }

  savedChanges = (e) => {
    if(typeof this.props.fcSavedChanges === "function"){
      this.props.fcSavedChanges(e);
    }
  }

  buttonCellClick = (e, functionReturn) => {
    e.event.preventDefault();
    if(functionReturn) this.props[functionReturn](e.row);
  }

  refreshDataGrid() {
    this.dataGridRef.current.instance.refresh();
  }

  exportingExcel = (e) => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'datos_argon.xlsx');
        });
      });
      e.cancel = true;
  }

  exportingPDF = () => {
    const doc = new jsPDF();
    const dataGrid = this.dataGridRef.current.instance;

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid,
    }).then(() => {
      doc.save('datos_argon.pdf');
    });
  }

  calculateHeight(){
    let heightValue = (hasContentVar(this.props.height))?this.props.height:190;
    return ((hasContentVar(this.props.fixedHeight))?heightValue:(window.innerHeight - heightValue));
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'exportpdf',
        onClick: this.exportingPDF.bind(this)
      }
    });
  }

  onCellPrepared(e) {
    if (e.rowType == 'data' && e.cellElement.classList.value.includes("validNumbersColors")) {
        e.cellElement.classList.add((e.value < 0?"bgRedGrid":(e.value == 0?"bgInherit":"bgGreenGrid")));
    }
    if (e.rowType == 'data' && e.cellElement.classList.value.includes("validZeroNumbersColors")) {
      e.cellElement.classList.add((e.value == 0?"bgInherit":"bgRedGrid"));
    }
  }

  render() {
    return (
      <DataGrid
        ref={this.dataGridRef}
        dataSource={this.props.data}
        allowColumnReordering={true}
        allowColumnResizing={true}
        showBorders={true}
        columnResizingMode="widget"
        columnMinWidth={50}
        height= {() => this.calculateHeight()}
        hoverStateEnabled={true}
        focusedRowEnabled={true}
        keyExpr= {((hasContentVar(this.props.keyExpr))?this.props.keyExpr:"GRID_MAIN")}
        onRowDblClick={this.rowDblClick}
        onRowClick={this.rowClick}
        onSaved={this.savedChanges}
        onExporting={this.exportingExcel}
        //onToolbarPreparing={this.onToolbarPreparing}
        onCellPrepared={this.onCellPrepared}
      >
        <GroupPanel visible={(hasContentVar(this.props.showGroup))?this.props.showGroup:true} />
        <Grouping contextMenuEnabled={true} expandMode="rowClick" autoExpandAll={((hasContentVar(this.props.groupExpandAll))?this.props.groupExpandAll:false)} />
        <SearchPanel visible={true} placeholder="Buscar" />
        <HeaderFilter visible={true} />
        <FilterRow visible={((hasContentVar(this.props.filterRow))?this.props.filterRow:true)} applyFilter="auto" />
        { 
          (hasContentVar(this.props.allowEdit) && this.props.allowEdit == true) && <Editing mode="cell" allowUpdating={true} /> 
        }

        {    
          (hasContentVar(this.props.allowEdit) && this.props.allowEdit == true) && <KeyboardNavigation editOnKeyPress={true} enterKeyAction={"moveFocus"} enterKeyDirection={"row"} />
        }

        {
          !hasContentVar(this.props.hideCountRows) &&
          <Column caption="#" dataType="string" width="50" cellRender={(data) => {return (data.row.rowIndex+1)} } />
        }
        
        {
          this.props.columns.map((columnLvl1, i) => {

            if(columnLvl1?.childrensColumn != undefined){
              if(columnLvl1.childrensColumn.length > 0){
                return (
                  <Column key={i} caption={columnLvl1.caption} alignment="center">
                    {
                      columnLvl1.childrensColumn.map((columnLvl2, z) => {
                        if(columnLvl2?.childrensColumn != undefined){
                          if(columnLvl2.childrensColumn.length > 0){
                            return (
                              <Column key={i+"-"+z} caption={columnLvl2.caption} alignment="center">
                                {
                                  columnLvl2.childrensColumn.map((columnLvl3, x) => {
                                    return (
                                      <Column key={i+"-"+z+"-"+x} {...columnLvl3} />
                                    );
                                  })
                                }
                              </Column>
                            );
                          }
                        }else{
                          return (
                            <Column key={i+"-"+z} {...columnLvl2} />
                          );
                        }
                      })
                    }
                  </Column>
                );
              }
            }else{
              return (
                <Column key={i} {...columnLvl1} />
              );
            }
            
          })
        }
      
        { this.renderButtons() }

        { this.renderTotales() }

        <Export enabled={true} />
        <Pager allowedPageSizes={[25, 50, 100]} showPageSizeSelector={true} showInfo={true} />
        <Paging defaultPageSize={((hasContentVar(this.props.pageSize))?this.props.pageSize:100)} />
      </DataGrid>
    );
  }
}

export default Grilla;
