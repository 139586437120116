import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import {
  Button, Card, CardHeader, CardBody, CardTitle, Collapse, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, FormGroup, Form, Input,
  InputGroupAddon, InputGroupText, InputGroup, Media, NavbarBrand, Navbar, NavItem,
  NavLink, Nav, Progress, Table, Container, Row, Col
} from "reactstrap";

import { getUser, removeUserSession } from "../../variables/utils";

//ASSETS
import userIMG from "assets/img/theme/team-4-800x800.jpg";

var ps;

class Sidebar extends React.Component {

  state = {
    collapseOpen: false
  };

  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };

  createLinks = (routes) => {
    let padres = [];
    routes.map((value, i) => {
      let filter = padres.filter(opc => opc == value.node);
      if(filter.length == 0) padres.push(value.node);
    });

    let rtn = [];
    padres.map((padre, keyP) => {
      if(padre != null) rtn.push(this.createLinkPadre(padre, (keyP+100)));
      routes.map((prop, key) => {
        if(prop.node == padre){
          rtn.push(this.createLinkHijos(prop, prop, key));
        }
      });
    });

    return rtn;
  };

  createLinkPadre = (nombre, i) => {
    return(
      <div key={i}>
        <hr className="my-3" />
        <h6 className="ml-4 mb-0 navbar-heading text-muted">{nombre}</h6>
      </div>
    );
  };

  createLinkHijos = (prop, opc, key) => {
    return(
      <NavItem key={key}>
        <NavLink
          to={opc.layout + opc.path}
          tag={NavLinkRRD}
          onClick={this.closeCollapse}
          activeClassName="active"
        >
          <i className={prop.icon} />
          {prop.name}
        </NavLink>
      </NavItem>
    );
  };

  logout = () => {
    removeUserSession();
    window.location.href = "/auth";
  }

  render() {
    const user = getUser();
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) { // INTERNO
      navbarBrandProps = { to: logo.innerLink, tag: Link };
    } else if (logo && logo.outterLink) { // EXTERNO
      navbarBrandProps = { href: logo.outterLink, target: "_blank" };
    }
    return (
      <Navbar id="sidenav-main" className="navbar-vertical fixed-left navbar-light bg-white" expand="md" >
        <Container fluid>
          {/* Toggler */}
          <button className="navbar-toggler" type="button" onClick={this.toggleCollapse}>
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand {...navbarBrandProps}>
              <img alt={logo.imgAlt} className="navbar-brand-img max-height-100 d-md-block d-none m-auto" src={logo.imgSrc} style={{width: "80px"}} />
              <label className="m-0 d-md-block d-none"><b className="color-blue">LM</b>SOLUCIONES</label>
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="USER IMG MOVIL" src={userIMG} />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title py-0" header tag="div">
                  <h6 className="text-center m-0 f-13"><span className="text-muted text-center f-9">Usuario:</span><br/>{user.nombre} <small>({user.empresa})</small></h6>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#" onClick={this.logout}>
                  <i className="ni ni-button-power" />
                  <span>Salir</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="8">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                        <label className="m-0 ml-2 text-dark"><b className="color-blue">LM</b>SOLUCIONES</label>
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                        <label className="m-0 ml-2 text-dark"><b className="color-blue">LM</b>SOLUCIONES</label>
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="4">
                  <button className="navbar-toggler" type="button" onClick={this.toggleCollapse}>
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input aria-label="Search" className="form-control-rounded form-control-prepended" placeholder="Buscar" type="search" />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <span className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form>
            {/* Navigation */}
            <Nav navbar>{this.createLinks(routes)}</Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

export default Sidebar;
