import React from "react";
import axios from 'axios';
import { Card, CardBody, Container, Row, Button, Col } from "reactstrap";
import 'jspdf-autotable';
import { urlAPI, configTokenAXI, toastr, getUser, getDateNow, hasContentVar } from "../../variables/utils";
// COMPONENTES
import Header from "components/Headers/Header.js";
import Grilla from "components/Grillas/Grilla.js";
import FieldsGroup from "components/FieldsGroup/FieldsGroup.js";
import Loading from "components/Loading/loading";
import * as moment from 'moment';

class AudVerificarBloqueoTimbradas extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      dataCombos : {clientes: []},
      form: {
        inpFecha: getDateNow(),
        inpCliente: null,
        inpTypeConsulta: true
      },
      dataGrid: [],
      columnsGrid: [
        {caption: "Placa", dataField: "placaVehi", dataType: "string", width: "100", sortOrder: "asc"},
        {caption: "Hora", dataField: "hora", dataType: "datetime", format: 'HH:mm:ss', width: "100", alignment: "center", allowHeaderFiltering: false, sortOrder: "asc"},
        {caption: "Sub", dataField: "sub", dataType: "number", width: "60", alignment: "center", allowHeaderFiltering: false},
        {caption: "Baj", dataField: "baj", dataType: "number", width: "60", alignment: "center", allowHeaderFiltering: false},
        {caption: "Informacion Viajes Gema", childrensColumn: [
          {caption: "N° Viaje", dataField: "idViaje", dataType: "number", width: "90", alignment: "center", allowHeaderFiltering: false},
          {caption: "Viaje", dataField: "viaje", dataType: "number", width: "60", alignment: "center", allowHeaderFiltering: false},
          {caption: "Hora Procesado", dataField: "dhorasistema", dataType: "datetime", format: 'HH:mm:ss', width: "120", alignment: "center", allowHeaderFiltering: false},
          {caption: "Hora Despacho", dataField: "dhoradespacho", dataType: "datetime", format: 'HH:mm:ss', width: "120", alignment: "center", allowHeaderFiltering: false},
          {caption: "Hora Llegada", dataField: "rhorallegada", dataType: "datetime", format: 'HH:mm:ss', width: "120", alignment: "center", allowHeaderFiltering: false},
        ]},
        {caption: "Posible Motivo", dataField: "posible_motivo", dataType: "string", minWidth: "200"},
      ],
      columnsGridTotales:[
        {column: "sub", summaryType: "sum", displayFormat: "{0}"},
        {column: "baj", summaryType: "sum", displayFormat: "{0}"},
      ],
      showLoading: false,
      blockCliente: false,
    };
  }

  //#region  *********** REQUEST ***********

  getDataCombos = async () => {
    let user = getUser();

    let arr1 = await axios.post(urlAPI + "/mae_clientes/getAllBasic", { idCliente: user.idCliente }, configTokenAXI);
    arr1.data.data.map(e=> { e.id = e.codigo});

    let rtn = {clientes: arr1.data.data};
    this.setState({ dataCombos: rtn, blockCliente: (user.idCliente?true:false) }, () => {
      if(user.idCliente) this.handleChangeInputs({id: user.idCliente}, "inpCliente");
    });
  }
  
  getDataGrid = () => {
    if(this.state.form.inpCliente == null){
      toastr.warn("¡Porfavor debe seleccionar un cliente!");
      return false;
    }

    if(this.state.form.inpFecha == ""){
      toastr.warn("¡Porfavor verificar la fecha ingresada!");
      return false;
    }

    this.setState({ showLoading: true });
    
    axios.post(urlAPI + "/aud_movilidad/getVerificacionBloqueoTimbradas", { idCliente: this.state.form.inpCliente, fecha: this.state.form.inpFecha, tipo: (this.state.form.inpTypeConsulta?1:0) }, configTokenAXI)
    .then(response => {
        this.setState({ showLoading: false, dataGrid: response.data.data });

    }).catch(error => {
        this.setState({ showLoading: false });

        let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
        toastr.error(res);
    });
  }

  //#endregion

  handleChangeInputs = (e, nombreInput = null) => {
    let name = "";
    let value = "";
    
    if(typeof e == "boolean"){ // SWITCHS
      name = nombreInput;
      value = e;
    }else if(typeof e.target === "undefined"){ // SELECTS
      name = nombreInput;
      value = e.id;
    }else{
      if(hasContentVar(e.target.attributes.maxLength)){
        if(e.target.value.length > e.target.attributes.maxLength.value) return false;
      }
      name = e.target.name;
      value = e.target.value;
    }

    this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
    }, () => {
      if(name == "inpTypeConsulta") this.getDataGrid();
    });
  }

  componentDidMount = (e) => {
    this.getDataCombos();
  }

  render() {
    return (
      <>
        <Loading show={this.state.showLoading} />

        <Header />
        <Container className="mt--10" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody className="py-3">
                  <div className="py-2 mb-2">
                      <h5 className="heading-small text-muted m-0 text-center f-20 p-0"> VERIFICACION DE LAS TIMBRADAS BLOQUEADAS POR DESPACHO </h5>
                  </div>
                  <div className="container-fluid bg-style-2 py-2 px-3 rounded">
                    <Row className="py-1 pb-2">
                      <Col sm="4" md="3" className="px-2">
                        <FieldsGroup type="select" id="inpCliente" caption="Cliente" data={this.state.dataCombos.clientes} nameOptionSelect="empresa" value={this.state.form} attr={{isDisabled: this.state.blockCliente, onChange: e => this.handleChangeInputs(e, "inpCliente"), placeholder: "Seleccionar"}} />
                      </Col>
                      <Col sm="3" md="2" className="px-2">
                        <FieldsGroup type="date" caption="Fecha:" id="inpFecha" value={this.state.form} attr={{onChange: e => this.handleChangeInputs(e) }} />
                      </Col>
                      <Col sm="3" md="2">
                        <label className="form-control-label"></label>
                        <Button className="btn-icon btn-3 center-div mt-2 py-2 px-2 w-100 f-13" color="primary" type="button" onClick={() => this.getDataGrid()}>
                          <i className="fas fa-search mr-2"></i> Buscar
                        </Button>
                      </Col>
                      <Col sm="1" md="4" className="pt-3 pr-1">
                        <label className="badge-primary text-monospace border-r-6 m-0 float-right font-13 mt-2 px-4 py-1 shadow-3 text-center">Mostrando las<br/>{this.state.form.inpTypeConsulta?"timbradas bloqueadas":"timbradas enviadas"}</label>
                      </Col>
                      <Col sm="1" md="1" className="pt-3">
                        <label className="f-12 text-muted m-0 font-12 text-center w-100 ml-1">Cambiar</label>
                        <br/>
                        <FieldsGroup className="float-right" type="switch" id="inpTypeConsulta" showLabel={false} withMargin={false} value={this.state.form} fcHandleChange={e => this.handleChangeInputs(e, "inpTypeConsulta")}  />
                      </Col>
                    </Row>
                  </div>
                  <Grilla 
                    data={this.state.dataGrid} 
                    columns={this.state.columnsGrid}
                    columnsTotales={this.state.columnsGridTotales}
                    height={310}
                    keyExpr="id"
                  />
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>

      </>
    );
  }
}

export default AudVerificarBloqueoTimbradas;
