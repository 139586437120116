import React from "react";
import axios from 'axios';
import { Card, CardBody, Container, Row, Button, Col } from "reactstrap";
import 'jspdf-autotable';
import { urlAPI, configTokenAXI, toastr, getUser, getDateNow, hasContentVar } from "../../variables/utils";
// COMPONENTES
import Header from "components/Headers/Header.js";
import Grilla from "components/Grillas/Grilla.js";
import FieldsGroup from "components/FieldsGroup/FieldsGroup.js";
import Loading from "components/Loading/loading";
import * as moment from 'moment';

import ModalsDetalleMov from "../aud_despachos/ModalsDetalleMov.js";

class AudVerificarMovilidad extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      dataCombos : {clientes: []},
      form: {
        inpFecha: getDateNow(),
        inpCliente: null,
      },
      dataGrid: [],
      columnsGrid: [
        {caption: "N° Viaje", dataField: "idViaje", dataType: "string", width: "110", allowHeaderFiltering: false},
        {caption: "Cliente", dataField: "empresa", dataType: "string", minWidth: "90"},
        {caption: "Vehiculo", dataField: "placaVehi", dataType: "string", width: "90"},
        {caption: "Hora Despacho", dataField: "inicio", dataType: "datetime", format: 'HH:mm:ss', width: "110", allowHeaderFiltering: false},
        {caption: "Hora Llegada", dataField: "final", dataType: "datetime", format: 'HH:mm:ss', width: "100", allowHeaderFiltering: false},
        {caption: "Subidas", childrensColumn: [
          {caption: "Inicio Des.", dataField: "subD", dataType: "number", width: "85", alignment: "center", allowHeaderFiltering: false},
          {caption: "Final Des.", dataField: "subF", dataType: "number", width: "85", alignment: "center", allowHeaderFiltering: false},
          {caption: "Total Despacho", dataField: "totSub", dataType: "number", width: "110", alignment: "center", allowHeaderFiltering: false},
          {caption: "Total Movilidad", dataField: "totSubMov", dataType: "number", width: "110", alignment: "center", allowHeaderFiltering: false},
          {caption: "Diff", dataField: "diffSub", dataType: "number", width: "80", alignment: "center", cssClass: "validZeroNumbersColors"},
        ]},
        {caption: "Bajadas", childrensColumn: [
          {caption: "Inicio Des.", dataField: "bajD", dataType: "number", width: "85", alignment: "center", allowHeaderFiltering: false},
          {caption: "Final Des.", dataField: "bajF", dataType: "number", width: "85", alignment: "center", allowHeaderFiltering: false},
          {caption: "Total Despacho", dataField: "totBaj", dataType: "number", width: "110", alignment: "center", allowHeaderFiltering: false},
          {caption: "Total Movilidad", dataField: "totBajMov", dataType: "number", width: "110", alignment: "center", allowHeaderFiltering: false},
          {caption: "Diff", dataField: "diffBaj", dataType: "number", width: "80", alignment: "center", cssClass: "validZeroNumbersColors"},
        ]},
      ],
      columnsGridTotales:[
        {column: "totSub", summaryType: "sum", displayFormat: "{0}"},
        {column: "totSubMov", summaryType: "sum", displayFormat: "{0}"},
        {column: "diffSub", summaryType: "sum", displayFormat: "{0}"},
        {column: "totBaj", summaryType: "sum", displayFormat: "{0}"},
        {column: "totBajMov", summaryType: "sum", displayFormat: "{0}"},
        {column: "diffBaj", summaryType: "sum", displayFormat: "{0}"},
      ],
      rowDataDetalleMov: [],
      showModalDetalleMov: false,
      showLoading: false,
      blockCliente: false,
    };
    this.modalDetalleMovElement = React.createRef();
  }

  //#region  *********** REQUEST ***********

  getDataCombos = async () => {
    let user = getUser();

    let arr1 = await axios.post(urlAPI + "/mae_clientes/getAllBasic", { idCliente: user.idCliente }, configTokenAXI);
    arr1.data.data.map(e=> { e.id = e.codigo});

    let rtn = {clientes: arr1.data.data};
    this.setState({ dataCombos: rtn, blockCliente: (user.idCliente?true:false) }, () => {
      if(user.idCliente) this.handleChangeInputs({id: user.idCliente}, "inpCliente");
    });
  }

  getDataGrid = () => {
    if(this.state.form.inpCliente == null){
      toastr.warn("¡Porfavor debe seleccionar un cliente!");
      return false;
    }

    if(this.state.form.inpFecha == ""){
      toastr.warn("¡Porfavor verificar la fecha ingresada!");
      return false;
    }

    this.setState({ showLoading: true });
    
    axios.post(urlAPI + "/aud_movilidad/getVerificacion", { idCliente: this.state.form.inpCliente, fecha: this.state.form.inpFecha }, configTokenAXI)
    .then(response => {
        this.setState({ showLoading: false, dataGrid: response.data.data });

    }).catch(error => {
        this.setState({ showLoading: false });

        let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
        toastr.error(res);
    });
  }

  //#endregion

  handleChangeInputs = (e, nombreInput = null) => {
    let name = "";
    let value = "";
    
    if(typeof e.target === "undefined"){ // SELECTS
      name = nombreInput;
      value = e.id;
    }else{
      if(hasContentVar(e.target.attributes.maxLength)){
        if(e.target.value.length > e.target.attributes.maxLength.value) return false;
      }
      name = e.target.name;
      value = e.target.value;
    }

    this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
    })
  }

  rowDoubleClick = async (e) => {
    let data = e.data;
    let temp = {
      idCliente: data.idCliente,
      empresa: data.empresa,
      placaVehi: data.placaVehi,
      fechaHora1: this.state.form.inpFecha + " " + data.inicio,
      fechaHora2: this.state.form.inpFecha + " " + data.final,
    };
    await this.setState({ rowDataDetalleMov: temp, showModalDetalleMov: true });
    this.modalDetalleMovElement.current.updateState();
  }

  closeModalDetalleMov = () => {
    this.setState({ showModalDetalleMov: false });
  };

  componentDidMount = (e) => {
    this.getDataCombos();
  }

  render() {
    return (
      <>
        <Loading show={this.state.showLoading} />

        <Header />
        <Container className="mt--10" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody className="py-3">
                  <div className="py-2 mb-2">
                      <h5 className="heading-small text-muted m-0 text-center f-20 p-0"> VERIFICACION DE DATOS DEL DESPACHO VS MOVILIDAD </h5>
                  </div>
                  <div className="container-fluid bg-style-2 py-2 px-3 rounded">
                    <Row className="py-1 pb-2">
                      <Col sm="4" md="3" className="px-2">
                        <FieldsGroup type="select" id="inpCliente" caption="Cliente" data={this.state.dataCombos.clientes} nameOptionSelect="empresa" value={this.state.form} attr={{isDisabled: this.state.blockCliente, onChange: e => this.handleChangeInputs(e, "inpCliente"), placeholder: "Seleccionar"}} />
                      </Col>
                      <Col sm="3" md="2" className="px-2">
                        <FieldsGroup type="date" caption="Fecha:" id="inpFecha" value={this.state.form} attr={{onChange: e => this.handleChangeInputs(e) }} />
                      </Col>
                      <Col sm="3" md="2">
                        <label className="form-control-label"></label>
                        <Button className="btn-icon btn-3 center-div mt-2 py-2 px-2 w-100 f-13" color="primary" type="button" onClick={() => this.getDataGrid()}>
                          <i className="fas fa-search mr-2"></i> Buscar
                        </Button>
                      </Col>
                      <Col sm="2" md="5" className="center-div-y justify-content-end pr-0">
                        <label className="f-13 text-muted opacity-08 w-75 m-0 text-right">* Doble click sobre un registro para ver el detallado de los registros de movilidad del viaje</label>
                      </Col>
                    </Row>
                  </div>
                  <Grilla 
                    data={this.state.dataGrid} 
                    columns={this.state.columnsGrid}
                    columnsTotales={this.state.columnsGridTotales}
                    height={310}
                    keyExpr="idViaje"
                    fcRowDblClick={this.rowDoubleClick}
                  />
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>

        <ModalsDetalleMov
          ref={this.modalDetalleMovElement}
          data={this.state.rowDataDetalleMov}
          show={this.state.showModalDetalleMov} 
          fcClose={this.closeModalDetalleMov}
        />

      </>
    );
  }
}

export default AudVerificarMovilidad;
