import React from "react";
import { Card, CardBody, CardText, CardHeader, Button } from "reactstrap";
// COMPONENTES

class CardHome extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
        <Card className="text-center shadow-lg p-5 border-rad-20">
            <CardHeader className="px-0 pt-0">
                <h4 className="text-uppercase ls-1 text-primary py-3 mb-0">Consolidado {this.props.title}</h4>
            </CardHeader>
            <CardBody className="px-0 pb-0">
                <div><label className="display-2">{this.props.data.total}</label> <small className="font-13 letter-spacing-1 opacity-5">{this.props.subtitle} Enviados</small></div>
                <CardText className="f-15 font-weight-500 mt-2">En el transcurso del dia de hoy</CardText>
                <hr />
                <CardText><small className="text-muted">Total informacion enviada el dia de hoy:</small></CardText>
                
                <ul className="list-unstyled my-4">
                    <li>
                        <div className="d-flex align-items-center">
                            <div className="icon icon-xs icon-shape bg-gradient-info text-white shadow rounded-circle">
                                <i className={this.props.icons[0]}></i>
                            </div>
                            <span className="pl-2 text-sm center-div">
                                <span className="mr-1 font-25 font-weight-600 mark">{this.props.data.total}</span>
                                <label className="font-12 letter-spacing-1 m-0 text-left text-muted ml-2">Total {this.props.subtitle} <br/> Enviados</label>
                            </span>
                        </div>
                    </li>
                    <li className="mt-2">
                        <div className="d-flex align-items-center">
                            <div className="icon icon-xs icon-shape bg-gradient-info text-white shadow rounded-circle">
                                <i className={this.props.icons[1]}></i>
                            </div>
                            <span className="pl-2 text-sm center-div">
                                <span className="mr-1 font-25 font-weight-600 mark">{this.props.data.num_vehiculos}</span>
                                <label className="font-12 letter-spacing-1 m-0 text-left text-muted ml-2">Total Vehiculos <br/> Compartiendo Informacion</label>
                            </span>
                        </div>
                    </li>
                </ul>
                <CardText className="f-14 my-2">Ultimo dato enviado a las<br/><small className="text-muted f-13 font-bold">{this.props.data.last_fecha}</small></CardText>

                <br/>
                <Button color="primary" href={this.props.urlRedirect}>Ver Mas</Button>

                <br/>
                <label className="text-muted opacity-05 font-weight-light f-14 mb-0 mt-5"><i className="fas fa-history opacity-05 mr-1"></i> Actualizado hace: <b>{this.props.minLastUpdate}</b> min</label>
            </CardBody>
        </Card>
    );
  }
}

export default CardHome;
