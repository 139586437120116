import React from "react";
import axios from 'axios';
import { Card, CardBody, Container, Row } from "reactstrap";
import 'jspdf-autotable';
import { urlAPI_SIBUS, configTokenSIBUS, toastr } from "../../variables/utils";
// COMPONENTES
import Header from "components/Headers/Header.js";
import Grilla from "components/Grillas/Grilla.js";
import Loading from "components/Loading/loading";

import ModalsViewFile from "./ModalsViewFile.js";

class ViewLogs extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      dataGrid: [],
      columnsGrid: [
        {caption: "Archivo Log", dataField: "name_file", dataType: "string", minWidth: "100"},
      ],
      nameFileViewFile: null,
      showModalViewFile: false,
      showLoading: false,
    };
    this.modalViewFileElement = React.createRef();
  }

  //#region  *********** REQUEST ***********

  getDataGrid = () => {
    this.setState({ showLoading: true });

    axios.get(urlAPI_SIBUS + "/internal/getAllFilesLogs", configTokenSIBUS)
    .then(response => {
        this.setState({ showLoading: false, dataGrid: response.data.data});

    }).catch(error => {
        this.setState({ showLoading: false });

        let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
        toastr.error(res);
    });
  }

  rowButtonViewFile = async (item) => {
    let data = item.data;

    await this.setState({ 
      nameFileViewFile: data.name_file,
      showModalViewFile: true
    });
    this.modalViewFileElement.current.modalMount();
  }

  closeModalViewFile = () => {
    this.setState({ showModalViewFile: false });
  };
  
  //#endregion

  componentDidMount = (e) => {
    this.getDataGrid();
  }

  render() {
    return (
      <>
        <Loading show={this.state.showLoading} />

        <Header />
        <Container className="mt--10" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody className="py-3">
                  <div className="py-2 rounded">
                    <h5 className="modal-title heading-small text-muted text-center f-20"> LISTA DE ARCHIVOS LOGS EXTERNOS </h5>
                    <h6 className="text-muted heading-small text-center subtitles"> ( Pulse el icono <i className="fas fa-eye"></i> para ver el archivo ) </h6>
                  </div>
                  <Grilla 
                    data={this.state.dataGrid} 
                    columns={this.state.columnsGrid}
                    columnsButtons={[
                      {cssClass: "text-primary mx-1", hint: "Ver Archivo", icon: "fas fa-eye", functionRtn: "fcButtonView"},
                    ]}
                    fcButtonView={this.rowButtonViewFile}
                    height={227}
                    keyExpr="id"
                  />
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>

        <ModalsViewFile
          ref={this.modalViewFileElement}
          nameFile={this.state.nameFileViewFile}
          show={this.state.showModalViewFile} 
          fcClose={this.closeModalViewFile}
        />

      </>
    );
  }
}

export default ViewLogs;
