import React from "react";
import { Row, Col, Button, Modal } from "reactstrap";
import { hasContentVar } from "../../variables/utils";
// COMPONENTES
import FieldsGroup from "components/FieldsGroup/FieldsGroup.js";

class Modals extends React.Component {
  
  state = {
    form: {
      id: 0,
      codigo: 0,
      estado_envio_despacho_ambq: 0,
      estado_envio_despacho_end_ambq: 0,
      estado_envio_movilidad_ambq: 0,
      estado_envio_boton_panico_ambq: 0,
    }
  }

  updateState = () => {
    let { data } = this.props;
    this.setState({
      form: {
        id: hasContentVar(data.id)?data.id:0,
        codigo: hasContentVar(data.codigo)?data.codigo:0,
        estado_envio_despacho_ambq: hasContentVar(data.estado_envio_despacho_ambq)?data.estado_envio_despacho_ambq:0,
        estado_envio_despacho_end_ambq: hasContentVar(data.estado_envio_despacho_end_ambq)?data.estado_envio_despacho_end_ambq:0,
        estado_envio_movilidad_ambq: hasContentVar(data.estado_envio_movilidad_ambq)?data.estado_envio_movilidad_ambq:0,
        estado_envio_boton_panico_ambq: hasContentVar(data.estado_envio_boton_panico_ambq)?data.estado_envio_boton_panico_ambq:0,
      }
    })
  }

  handleChangeInputs = (e, nombreInput = null) => {
    let name = "";
    let value = "";
    
    if(typeof e.target === "undefined"){ // SELECTS
      name = nombreInput.name;
      value = e.id;
    }else{
      if(e.target.attributes != undefined && hasContentVar(e.target.attributes.maxLength)){
        if(e.target.value.length > e.target.attributes.maxLength.value) return false;
      }
      name = e.target.name;
      value = e.target.value;
    }

    this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
    });
  }

  closeModal = () => {
      this.props.fcClose();
  }

  aceptarModal = () => {
      this.props.fcAceptar(this.state.form);
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.show} className="modal-dialog-centered modal-md">
          <div className="modal-header">
            <h5 className="modal-title heading-small text-muted"> Configurar Estados de Envio </h5>
          </div>
          <div className="modal-body py-3">
            <div className="px-lg-4">
              <Row>
                <Col sm="6">
                  <FieldsGroup type="select" caption="Envio Despacho" id="estado_envio_despacho_ambq" data={[{id: 1,  nombre: "SI"}, {id: 0,  nombre: "NO"}]} value={this.state.form} attr={{onChange: this.handleChangeInputs}} />
                </Col>
                <Col sm="6">
                  <FieldsGroup type="select" caption="Envio Finalizacion" id="estado_envio_despacho_end_ambq" data={[{id: 1,  nombre: "SI"}, {id: 0,  nombre: "NO"}]} value={this.state.form} attr={{onChange: this.handleChangeInputs}} />
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <FieldsGroup type="select" caption="Envio Movilidad" id="estado_envio_movilidad_ambq" data={[{id: 1,  nombre: "SI"}, {id: 0,  nombre: "NO"}]} value={this.state.form} attr={{onChange: this.handleChangeInputs}} />
                </Col>
                <Col sm="6">
                  <FieldsGroup type="select" caption="Envio Boton Panico" id="estado_envio_boton_panico_ambq" data={[{id: 1,  nombre: "SI"}, {id: 0,  nombre: "NO"}]} value={this.state.form} attr={{onChange: this.handleChangeInputs}} />
                </Col>
              </Row>
            </div>
          </div>
          <div className="modal-footer">
            <Button color="primary" className="btn-sm" type="button" onClick={this.aceptarModal} > Guardar </Button>
            <Button color="light" className="btn-sm" data-dismiss="modal" type="button" onClick={this.closeModal} > Cerrar </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default Modals;