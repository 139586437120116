import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import { ToastContainer } from 'react-toastify';

// COMPONENTES
import Navbars from "components/Navbars/Navbars.js";
import Footer from "components/Footers/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import { getUser, hasContentVar } from "../variables/utils";

//ASSETS
import logoIMG from "assets/img/logo.png";

import routes from "routes.js";

class Admin extends React.Component {

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  getRoutes = (getHidden = true) => {
    let user = getUser();
    let isAdmin = (user.admin==1?true:false);

    return routes.filter(e => {
        let isOnlyAdmin = ((hasContentVar(e?.onlyAdmin) && e?.onlyAdmin == true)?true:false);
        let isHidden = ((hasContentVar(e?.hidden) && e?.hidden == true)?true:false);

        let rtn = true;

        if(isOnlyAdmin && !isAdmin){
          rtn = false;
        }

        if(!getHidden && isHidden){
          rtn = false;
        }

        return rtn;
    });
  }

  getRoutesViews = () => {
    let _routes = this.getRoutes();
    return _routes.map((prop, key) => {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
    });
  };

  getBrandText = path => {
    return "ARGON APP";
  };

  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={this.getRoutes(false)}
          logo={{ innerLink: "/admin/home", imgSrc: logoIMG, imgAlt: "LOGO LM" }}
        />
        <div className="main-content" ref="mainContent">
          <Navbars
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutesViews()}
            <Redirect from="*" to="/admin/home" />
          </Switch>
          <ToastContainer />
        </div>
      </>
    );
  }
}

export default Admin;
