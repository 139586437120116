import React from "react";
import { Button, Modal } from "reactstrap";
import Grilla from "components/Grillas/Grilla.js";

import ModalViewJSON from "components/Modals/ModalViewJSON.js";

class ModalsItinerarios extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataGrid: [],
      columnsGrid: [
        {caption: "ID Itinerario", dataField: "id", dataType: "string", width: "275"},
        {caption: "Nombre", dataField: "name", dataType: "string", minWidth: "200"},
        {caption: "Activa", dataField: "active", dataType: "boolean", width: "90"},
      ],
      rowData: [],
      showModalViewJSON: false,
    }
    this.modalViewJSONElement = React.createRef();
  }

  updateState = () => {
    //this.getDataGrid();
  }

  rowButtonView = async (item) => {
    let data = item.data;

    await this.setState({ 
      rowData: data,
      showModalViewJSON: true
    });
  }

  closeModalViewJSON = () => {
    this.setState({ showModalViewJSON: false });
  };

  closeModal = () => {
      this.props.fcClose();
  }

  render() {
    return (
      <>

        <Modal isOpen={this.props.show} className="modal-dialog-centered modal-lg">
          <div className="modal-body py-3">
            <div className="px-lg-3">
              <label className="f-14 letter-spacing-2 m-0 opacity-6 text-center text-muted w-100">RUTA</label>
              <h5 className="heading-small text-muted text-center f-16 mb-0 pt-0">{this.props.data.name}</h5>
              <hr className="my-3" />
              
              <Grilla 
                data={this.props.listItinerarios}
                columns={this.state.columnsGrid}
                columnsButtons={[
                  {cssClass: "text-muted mx-1", hint: "Ver Objeto JSON", icon: "fas fa-eye", functionRtn: "fcButtonView"}
                ]}
                fcButtonView={this.rowButtonView}
                fixedHeight={true}
                height={450}
                keyExpr="id"
              />

            </div>
          </div>
          <div className="modal-footer pt-0">
            <Button color="light" className="btn-sm" data-dismiss="modal" type="button" onClick={this.closeModal} > Cerrar </Button>
          </div>
        </Modal>

        <ModalViewJSON
          ref={this.modalViewJSONElement}
          title={"ITINERARIO"}
          subtitle={this.state.rowData?.name}
          dataJSON={this.state.rowData}
          show={this.state.showModalViewJSON} 
          fcClose={this.closeModalViewJSON}
        />

      </>
    );
  }
}

export default ModalsItinerarios;