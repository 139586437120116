import React from "react";
import axios from 'axios';
import { Button, Modal } from "reactstrap";
import { urlAPI, configTokenAXI, toastr } from "../../variables/utils";

import Grilla from "components/Grillas/Grilla.js";
import Loading from "components/Loading/loading";

class ModalsDetalleMov extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {
        idCliente: null,
        empresa: null,
        placaVehi: null,
        fechaHora1: null,
        fechaHora2: null,
      },
      dataGrid: [],
      columnsGrid: [
        {caption: "Fecha Hora", dataField: "fechahora", dataType: "datetime", format: 'dd/MM/yyyy HH:mm:ss', minWidth: "150", allowHeaderFiltering: false},
        {caption: "Subidas", dataField: "sub", dataType: "number", width: "90", alignment: "center", allowHeaderFiltering: false},
        {caption: "Bajadas", dataField: "baj", dataType: "number", width: "90", alignment: "center", allowHeaderFiltering: false},
        {caption: "Puerta 1", dataField: "isPuerta1", dataType: "boolean", width: "100", alignment: "center"},
        {caption: "Rumbo", dataField: "course", dataType: "string", width: "90", alignment: "center", allowHeaderFiltering: false},
        {caption: "Velocidad (km/h)", dataField: "speed", dataType: "string", width: "130", alignment: "center", allowHeaderFiltering: false},
      ],
      columnsGridTotales:[
        {column: "sub", summaryType: "sum", displayFormat: "{0}"},
        {column: "baj", summaryType: "sum", displayFormat: "{0}"},
      ],
      showLoading: false
    }
  }

  updateState = () => {
    let { data } = this.props;
    this.setState({
      form: data
    });
    this.getDataGrid();
  }

  //#region  *********** REQUEST ***********

  getDataGrid = () => {
    this.setState({ showLoading: true });
    
    axios.post(urlAPI + "/aud_movilidad/getDetalleByFechas", { 
        idCliente: this.state.form.idCliente,
        placa: this.state.form.placaVehi,
        f1: this.state.form.fechaHora1, 
        f2: this.state.form.fechaHora2
    }, configTokenAXI)
    .then(response => {
        this.setState({ showLoading: false, dataGrid: response.data.data });

    }).catch(error => {
        this.setState({ showLoading: false, dataGrid: [] });

        let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
        toastr.error(res);
    });
  }

  //#endregion

  closeModal = () => {
      this.props.fcClose();
  }

  render() {
    return (
      <>
        <Loading show={this.state.showLoading} />

        <Modal isOpen={this.props.show} className="modal-dialog-centered modal-lg">
          <div className="modal-body py-3">
            <div className="px-lg-3">
              <h5 className="heading-small text-muted text-center pt-3 f-16 mb-0">Historial Pasajeros Vehiculo {this.state.form.placaVehi} (Empresa: {this.state.form.empresa})</h5>
              <label className="w-100 text-center mb-4 f-14 text-muted">Hora de Salida: <mark>{this.state.form.fechaHora1}</mark> / Hora de Llegada: <mark>{this.state.form.fechaHora2}</mark></label>

              <Grilla 
                data={this.state.dataGrid} 
                columns={this.state.columnsGrid}
                columnsTotales={this.state.columnsGridTotales}
                fixedHeight={true}
                height={450}
                keyExpr="id"
              />

            </div>
          </div>
          <div className="modal-footer pt-0">
            <Button color="light" className="btn-sm" data-dismiss="modal" type="button" onClick={this.closeModal} > Cerrar </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default ModalsDetalleMov;