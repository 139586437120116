import React from "react";
import axios from 'axios';
import { Container, Row } from "reactstrap";
import { urlAPI, configTokenAXI, toastr, getUser } from "../../variables/utils";
// COMPONENTES
import Header from "components/Headers/Header.js";
import CardHome from "./CardHome.js";

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataMovilidad: [],
      dataDespachos: [],
      minLastUpdate: 0
    };
    this.timer = null;
    this.timerMin = null;
  }

  getDataMovilidad = () => {
    let user = getUser();
    
    axios.post(urlAPI + "/aud_movilidad/getTotals", { idCliente: user.idCliente }, configTokenAXI)
    .then(response => {
        this.setState({ dataMovilidad: response.data.data[0], minLastUpdate: 0 });

    }).catch(error => {
        let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
        toastr.error(res);
    });
  }

  getDataDespachos = () => {
    let user = getUser();
    
    axios.post(urlAPI + "/aud_despachos/getTotals", { idCliente: user.idCliente }, configTokenAXI)
    .then(response => {
        this.setState({ dataDespachos: response.data.data[0], minLastUpdate: 0 });

    }).catch(error => {
        let res = (typeof error.response === "undefined")?"Error al acceder al servidor":error.response.data.message;
        toastr.error(res);
    });
  }

  componentDidMount = (e) => {
    this.getDataMovilidad();
    this.getDataDespachos();

    this.timer = setInterval(() => {
      this.getDataMovilidad();
      this.getDataDespachos();  
    }, 180000);

    this.timerMin = setInterval(() => {
      this.setState((prevState) => ({
        minLastUpdate: prevState.minLastUpdate + 1,
      }));
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    clearInterval(this.timerMin);
  }

  render() {
    return (
      <>
        <Header />
        <Container className="mt--10" fluid>
          <br/><br/>
          <Row>
              <div className="col-xl-5 col-lg-12 offset-xl-1">
                <div className="height-card-home px-4 mb-4">
                  <CardHome data={this.state.dataMovilidad} title={"Movilidad"} subtitle={"Registros"} icons={["fas fa-walking", "fas fa-bus-alt"]} urlRedirect={"/admin/aud_movilidad"} minLastUpdate={this.state.minLastUpdate} />
                </div>
              </div>
              <div className="col-xl-5 col-lg-12">
                <div className="height-card-home px-4 mb-4">
                  <CardHome data={this.state.dataDespachos} title={"Despachos"} subtitle={"Despachos"} icons={["fas fa-route", "fas fa-bus-alt"]} urlRedirect={"/admin/aud_despachos"} minLastUpdate={this.state.minLastUpdate} /> 
                </div>
              </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Home;
